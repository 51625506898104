import iDate from '../../interfaces/iDate';

const dateReducer = (state: iDate = { day: 0, month: 0, year: 0 },
                     action: any) => {
    switch(action.type) {
        case 'GET_DATE':
            return getActualDate();
        default:
            return state;
    }
}

/**
 *  Gets actual date (day, month, year)
 *  @returns { iDate }
 */
const getActualDate = (): iDate => {
    const newDate: Date = new Date();

    return { day: +newDate.getDate(), month: +newDate.getMonth()+1, year: +newDate.getFullYear() };
};

export default dateReducer;
