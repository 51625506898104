// System
import {v4 as uuid} from 'uuid';

// App
import {IDoctor, IRetiredDoctor} from '../interfaces/iDoctor';
import {IProfCenterDoctor} from '../interfaces/IProfCenterDoctor';
import {IAdminEmployee} from '../interfaces/iEmployee';

/**
 * Sort doctors data by name
 * Set unique ids
 *
 * @param {IDoctor[] | IProfCenterDoctor[]| IAdminEmployee[] | IAdminEmployee[][]} data
 * @returns {IDoctor[] | IProfCenterDoctor[]| IAdminEmployee[] | IAdminEmployee[][]}
 */
export const sortDataSetIds = (data: IDoctor[] | IProfCenterDoctor[] | IAdminEmployee[] | IAdminEmployee[][]): IDoctor[] | IProfCenterDoctor[] | IAdminEmployee[] | IAdminEmployee[][] => {
    data.forEach((el: IDoctor | IProfCenterDoctor | IAdminEmployee | IAdminEmployee[]) => {
        if (!(el as IAdminEmployee[]).length) {
            (el as IDoctor | IProfCenterDoctor | IAdminEmployee).id = uuid();
        } else {
            // set administration employees ids
            (el as IAdminEmployee[]).forEach(el => el.id = uuid())
        }
    });

    if (!(data as any).some((el: any) => el.sortable)) {
        (data as IDoctor[] | IProfCenterDoctor[]).sort((a: IDoctor | IProfCenterDoctor, b: IDoctor | IProfCenterDoctor) => a.name > b.name
            ? 1
            : b.name > a.name ? -1 : 0);
    }

    return data;
}

/**
 * Set unique ids for retired doctors
 * @param {IRetiredDoctor[]} doctors
 * @returns {IRetiredDoctor[]}
 */
export const setRetiredDoctorsIds = (doctors: IRetiredDoctor[]): IRetiredDoctor[] => {
    doctors.forEach((doc: IRetiredDoctor) => doc.id = uuid())
    return doctors;
}
