export interface IRoutes {
  MAIN: string;
  ANNOUNCEMENTS: string;
  ADMINISTRATION: string;
  EMPLOYEES: string;
  CONTACTS: string;
  DEPARTMENTS: string;
  PUBLIC_INFO: string;
  ARTICLES: string;
  COMMANDS: string;
  ONLINE_RECEPTION: string;
  VPO: string;
}

export const ROUTES: IRoutes = {
  MAIN: "/",
  ANNOUNCEMENTS: "/announcements",
  ADMINISTRATION: "/about/administration",
  EMPLOYEES: "/about/employees",
  CONTACTS: "/about/contacts",
  DEPARTMENTS: "/departments",
  PUBLIC_INFO: "/public-info",
  ARTICLES: "/articles",
  COMMANDS: "/commands",
  ONLINE_RECEPTION: "/online-reception",
  VPO: "/vpo",
};
