import iDeclarations from '../../interfaces/iDeclarations';
import {IDoctor} from '../../interfaces/iDoctor';
import {getDepartments} from '../../helpers/departments';

const declarationsReducer = (state: iDeclarations = { dep1: 0, dep2: 0, dep3: 0, depsSum: 0 },
                             action: any) => {
    switch(action.type) {
        case 'GET_DECLARATIONS':
            return calculateDeclarations();
        default:
            return state;
    }
}

/**
 *  Calculates general amount of declarations per department
 *
 *  @returns iDeclarations
 */
const calculateDeclarations = (): iDeclarations => {
    const departments: IDoctor[][] = getDepartments();
    const data: number[] = [];
    let sum: number = 0;

    departments.forEach((department: any) => {
        (department as number[]).forEach((el: any) => {
            sum += +el.declarations.amount;
        })

        data.push(sum);
        sum = 0;
    });

    return { dep1: data[0], dep2: data[1], dep3: data[2], depsSum: data[0] + data[1] + data[2] };
};

export default declarationsReducer;
