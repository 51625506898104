import employees from './data/employees.json';
import {IDoctor} from '../interfaces/iDoctor';
import {sortDataSetIds} from './doctorsIds';

/**
 * Gets departments data
 *
 * @returns IDoctor[][]
 */
export const getDepartments = (): IDoctor[][] => {
    let doctors: IDoctor[] = JSON.parse(JSON.stringify(employees.doctors))
        .filter((doctor: IDoctor) => !!doctor.declarations);

    doctors = (sortDataSetIds(doctors) as IDoctor[]);

    const departments: IDoctor[][] = [];

    for(let i: number = 1; i < 4; i++) {
        const filteredData: IDoctor[] = doctors.filter((doctor: IDoctor): boolean => {
            // @ts-ignore
            const number: number | null | undefined = parseInt(doctor?.department?.number) || null;
            return number === i;
        });
        departments.push(filteredData);
    }

    return departments;
}
