import iDate, { IFullDate } from "../interfaces/iDate";
import { useDispatch, useSelector } from "react-redux";
import IReduxState from "../interfaces/iReduxState";
import { useEffect } from "react";
import { getDate } from "../redux/actions";

export const useGetAndFormatDate = (): IFullDate => {
  const dispatch = useDispatch();

  const { day, month, year }: iDate = useSelector(
    (state: IReduxState) => state.date,
  );
  const date: string =
    month < 10 ? `${day}.0${month}.${year}` : `${day}.${month}.${year}`;

  useEffect(() => {
    if (!day || !month || !year) {
      dispatch(getDate());
    }
  }, [day, month, year, dispatch]);

  return {
    day,
    month,
    year,
    date,
  };
};
