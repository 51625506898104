// System
import React, { useEffect } from "react";
import { HashLink as Link } from "react-router-hash-link";
import { useDispatch, useSelector } from "react-redux";

// App
import "./css/Main.css";
import { getDeclarations } from "../../redux/actions";
import iDeclarations from "../../interfaces/iDeclarations";
import IReduxState from "../../interfaces/iReduxState";
import vaccinationPortal from "../../assets/docs/vaccination-portal.pdf";
import { useGetAndFormatDate } from "../../hooks/useGetAndFormatDate";
import mozSurvey from "./../../assets/images/moz-survey.jpg";

const pageTitle: string = "Головна сторінка";

export const Main: React.FC = () => {
  const { date } = useGetAndFormatDate();
  const { dep1, dep2, dep3, depsSum }: iDeclarations = useSelector(
    (state: IReduxState) => state.declarations,
  );
  const dispatch = useDispatch();

  // онлайн запис до лікаря
  const medCabUrl: string =
    "https://h24.ua/organizacia/8198-knp-tretij-cherkaskyj-miskyj-centr-pervynnoyi-med/doctors";

  useEffect(() => {
    document.title = pageTitle;
    window.scrollTo(0, 0);

    if (!dep1 && !dep2 && !dep3 && !depsSum) {
      dispatch(getDeclarations());
    }
  }, [dep1, dep2, dep3, depsSum, dispatch]);

  return (
    <div className="Main">
      <div className="container-custom">
        <section className="content">
          <h3 className="title">Застосунок Health24</h3>
          <div className="textbox">
            <div className="video-frame">
              <h4>Презентація застосунку Health24</h4>
              <iframe
                src="https://drive.google.com/file/d/18_SWtpZtM4mfXPbVY85-p-LuCyLDCEFE/preview"
                width="640"
                height="480"
                allow="autoplay"
              ></iframe>
            </div>
            <div className="video-frame">
              <h4>Як зареєструватися у застосунку Health24</h4>
              <iframe
                src="https://drive.google.com/file/d/1DK_lfkn8vmx7pdnrPaPcNijWZMm8a4dY/preview"
                width="640"
                height="480"
                allow="autoplay"
              ></iframe>
            </div>
            <div className="video-frame">
              <h4>Як додати дитину у застосунку Health24</h4>
              <iframe
                src="https://drive.google.com/file/d/1IAlPlIc5FiA4oi0Ug5DpQUzW4S1sAQ_R/preview"
                width="640"
                height="480"
                allow="autoplay"
              ></iframe>
            </div>
          </div>
        </section>
        <section className="content">
          <h3 className="title">
            Опитування про зміни в системі охорони здоров'я
          </h3>
          <div className="textbox">
            <div className="centered-box">
              <img className="image" src={mozSurvey} alt="logo" />
            </div>
            <br />
            <p className="text">
              Хочете, аби медичний сервіс в Україні був якіснішим і зручнішим?
              Розкажіть нам про свій досвід взаємодії з системою охорони
              здоров‘я.
            </p>
            <p className="text">
              Міністерство охорони здоров‘я України спільно з Проектом USAID
              “Підтримка реформи охорони здоров‘я” запускають всеукраїнське
              опитування “Голос пацієнтів”, аби дізнатися, як ви сприймаєте і
              ставитесь до змін в українській медицині.
            </p>
            <div className="centered-box">
              <a
                href="https://bit.ly/3TC8fo0"
                target="_blank"
                rel="noreferrer"
                className="navlink"
              >
                Анкета опитування
              </a>
            </div>
            <p className="text">
              Будь ласка, приділіть їй декілька хвилин. Від ваших відповідей
              залежить те, якою буде система охорони здоровʼя України у
              майбутньому. Дякуємо!
            </p>
            <br />
          </div>
        </section>
        <section className="content">
          <h3 className="title">Мобільні аптечні пункти</h3>
          <div className="textbox">
            <br />
            <p className="text">Що таке мобільний аптечний пункт?</p>
            <p className="text">
              Це – автівка з необхідним обладнанням для зберігання препаратів і,
              звісно ж, самі ліки. Будуть відпускатись як безрецептурні, так і
              рецептурні ліки. Всі рецептурні препарати відпускатимуть за
              паперовими та електронними рецептами.
            </p>
            <p className="text">
              У мобільному аптечному пункті будуть відпускатися лікарські
              засоби, які підлягають реімбурсації за програмою "Доступні ліки":
            </p>
            <ul className="items m-l-3em">
              <li className="items__item">
                для лікування серцево-судинних хвороб;
              </li>
              <li className="items__item">
                для профілактики інсультів та інфарктів;
              </li>
              <li className="items__item">
                проти нецукрового та цукрового діабетів;
              </li>
              <li className="items__item">
                для лікування хронічних хвороб нижніх дихальних шляхів;
              </li>
              <li className="items__item">
                розладів психіки, поведінки, епілепсії тощо;
              </li>
              <li className="items__item">інсуліни та медичні вироби.</li>
            </ul>
            <p className="text">
              Багато сіл області не мають аптечних пунктів. Людям доводиться
              їздити в центр громади, або й до найближчого міста. Це не зручно,
              а дехто взагалі не має можливості. Тому вирішили запровадити такий
              проєкт і доставити ліки в села громади, де немає аптек.
            </p>
            <p className="text">
              Проєкт мініаптеки на колесах запровадили на базі обласного
              підприємства "Фармація". Вже розробили 8 маршрутів. Перший
              розпочав роботу 8 лютого у Мошнівській громаді
              (Тубільці-Первомайське-Хрещатик-Березняки-Шелепухи-Кумейки). Далі
              - на Золотоніщину. Люди будуть отримувати інформацію щодо прибуття
              мобільного аптечного пункту від сімейного лікаря, органів місцевої
              влади, з керівниками яких погоджено маршрути.
            </p>
            <br />
          </div>
        </section>
        <section className="content">
          <h3 className="title">Національний портал з імунізації</h3>
          <div className="textbox">
            <div className="video-frame">
              <iframe
                className={"video"}
                title={"vaccination-portal"}
                src={vaccinationPortal}
                width={500}
                height={600}
              ></iframe>
            </div>
          </div>
        </section>
        <section className="content">
          <h3 className="title">
            Надання первинної медичної допомоги у період тимчасової відсутності
            лікаря у звʼязку з його відпусткою чи іншими обставинами
          </h3>
          <div className="textbox">
            <br />
            {
              'Детальніше про визначення заміни лікарів на сторінці "Публічна інформація" у відповідному розділі - '
            }
            <Link
              to="/public-info#doctors-exchange-program"
              className="navlink"
            >
              тут
            </Link>
            <br />
            <br />
          </div>
        </section>
        <section className="content">
          <h3 className="title">Записатися до лікаря онлайн через Health24</h3>
          <div className="textbox">
            <div className="video-frame">
              <iframe
                className={"video"}
                title={"health-24-registration-online"}
                src="https://www.youtube.com/embed/mmCMtQEvSRs"
                width="720"
                height="480"
                allow="autoplay"
              ></iframe>
            </div>
          </div>
        </section>
        <section className="content">
          <h3 className="title">Психосоціальна підтримка</h3>
          <div className="textbox">
            <br />
            <p className="text">Шановні пацієнти!</p>
            <p className="text">
              З метою оцінки потреб у сфері ментального здоров’я та
              психосоціальної підтримки адміністрація КНП «Третій Черкаський
              міський центр ПМСД» пропонує Вам пройти анкетування за{" "}
              <a
                href={"https://forms.gle/32zfoa3Aqm2Qhugn9"}
                target="_blank"
                rel="noopener noreferrer"
              >
                Google формою
              </a>
            </p>
            <br />
          </div>
        </section>
        <section className="content">
          <h3 className="title">Сервіс "Мій МЕДКАБІНЕТ" скасовано</h3>
          <div className="textbox">
            <br />
            <p className="text">
              Сервіс "Мій МЕДКАБІНЕТ" скасовано, для онлайн запису до лікаря діє
              інше посилання -{" "}
              <a href={medCabUrl} target="_blank" rel="noopener noreferrer">
                запис онлайн
              </a>
            </p>
            <br />
          </div>
        </section>
        <section className="content">
          <h3 className="title">Міжнародний день медичних сестер</h3>
          <div className="textbox">
            <div className="video-frame">
              <iframe
                className={"video"}
                title={"med-video"}
                src="https://drive.google.com/file/d/1FZEXO63zDAKZhPARTOBB10BWtl8VSGtK/preview"
                width="720"
                height="480"
                allow="autoplay"
              ></iframe>
            </div>
          </div>
        </section>
        <section className="content">
          <h3 className="title">
            З серпня аптеки будуть відпускати антибіотики тільки за рецептом.
            Виключення - окуповані території та райони проведення бойових дій
          </h3>
          <div className="textbox">
            <p className="text">
              Пацієнти отримуватимуть електронний рецепт для придбання
              антибіотиків за власні кошти уже з серпня 2022 року. Норма щодо
              відпуску антибіотиків лизе за призначенням лікаря в Україні не є
              новою, з серпня лише додається цифровий інструмент - електронний
              рецепт. Щобільше, рішення є частиною євтроінтеграційних процесів
              України, адже в багатьох країн світу відпуск антибіотиків
              відбувається саме за рецептом.
            </p>
            <p className="text">
              З кожним роком лікувати бактеріальні інфекційні хвороби (до
              пркиладу, пневмонію чи туберкульоз) стає все складніше. Уже зовсім
              скоро нераціональне використання антибіотиків може призвести до
              тогоЮ що інфекційні хвороби, які на разі швидко і без наслідків
              лікуються антибактеріальними препаратами, можуть стати смертельно
              небезпечними.
            </p>
            <p className="text">
              Згідно з даними Центру громадського здоров'я майже 64% українців
              помилково вважають, що гострі вірусні інфекційні захворювання та
              застуду можна вилікувати антибіотиками та купують їх без
              призначення лікаря. Ризик вироблення антибіотикорезистентності
              зростає і при недотриманні режиму прийому ліків в ситуаціях, коли
              вони потрібні. Саме тому лікування антибіотиками має призначатися
              та контролюватися виключно лікарем.
            </p>
            <p className="text">
              Саме тому впровадження електронного рецепта на антибіотики стане
              першим кроком у розв'язанні проблеми неконтрольованого вживання
              рецептурних лікарських засобів.
            </p>
            <p className="text">
              Нововведення почне працювати в рамках перехідного періоду в
              медичних закладах, що працюють з електронною системою охорони
              здоров'я (ЕСОЗ). Якщо заклад охорони здоров'я, до якого
              звертається пацієнт, не підключений до ЕСОЗ, лікар має виписати
              паперовий рецепт.
            </p>
            <p className="text">
              На перехідному періоді пацієнт зможе придбати необхідні ліки за
              рецептом у будь-якій аптеці. Для цього достатньо лише пред'явити
              фармацевту інформаційну довідку, яку лікар видасть як
              підтвердження призначення.
            </p>
            <p className="text">
              Виключення буде діяти тільки для тих аптек, що знаходяться в межах
              територіальних громад, які розташовані в районі проведення воєнних
              (бойових) дій або які перебувають в тимчасовій окупації, оточенні
              (блокуванні), перелік яких затверджений відповідним наказом
              Міністерства з питань реінтеграції тимчасово окупованих територій.
              Такі аптеки тимчасово на час дії воєнного стану зможуть відпускати
              пацієнтам антибіотики без рецепта лікаря.
            </p>
            <h4 className="subtitle">
              Чи обов'язково мати декларацію з сімейним лікарем для отримання
              рецепта?
            </h4>
            <p className="text">
              Рецепт можна буде отримати у будь-якого лікаря, незалежно від
              наявності декларації з сімейним лікарем.
            </p>
            <h4 className="subtitle">
              Чи зможе лікар приватної клініки виписати електронний рецепт?
            </h4>
            <p className="text">
              Лікарі усіх рівнів медичної допомоги, незалежно від форми
              власності закладу, зможуть здійснювати відпуск електронних
              рецептів за умови підключення закладу та його працівників до
              електронної системи охорони здоров'я.
            </p>
            <h4 className="subtitle">
              Що робити, якщо медичний заклад, до якого звертається пацієнт, не
              працює з електронною системою охорони здоров'я (ЕСОЗ)?
            </h4>
            <p className="text">
              У такому разі на перехідному періоді передбачається виписка
              паперового рецепта на антибіотики.
            </p>
            <h4 className="subtitle">
              Чи передбачено часткове погашення електронних рецептів? Чи можна
              буде придбати меншу кількість препарату, ніж вказано у рецепті?
            </h4>
            <p className="text">
              Так, функціонал електронного рецепта дозволяє погасити рецепт
              частково, тобто придбати меншу кількість лікарського засобу, ніж
              зазначено в рецепті.
            </p>
            <h4 className="subtitle">
              Чи поширюється електронний рецепт на очні краплі/топічні
              антибіотики й т.д.?
            </h4>
            <p className="text">
              Так, усі препарати, які відносяться до антибактеріальних
              лікарських засобів (код ATX-J01 згідно з даними Державного реєстру
              лікарських засобів України), підлягають відпуску за рецептом за
              кошти пацієнта. Ця норма стосується усіх форм антибактеріальних
              лікарських засобів, які підпадають під визначену групу.
            </p>
            <h4 className="subtitle">
              Чи будуть діяти паперові рецепти для відпуску препаратів на
              пільгових умовах?
            </h4>
            <p className="text">
              Так, на перехідний період передбачено виписування паперового
              рецепта медичними закладами, які поки не мають відповідної
              технічної можливості, тобто не підключені до ЕСОЗ. Крім цього,
              виписка лікарських препаратів, що відпускаються на пільгових
              умовах, буде здійснюватися за паперовим рецептом.
            </p>
            <h4 className="subtitle">
              Як в нових умовах відпуску антибіотиків можна буде придбати ліки
              для тварин?
            </h4>
            <p className="text">
              Лікарські препарати для тварин як і раніше можна буде придбати за
              ветеринарним рецептом.
            </p>
          </div>
        </section>
        <section className="contact">
          <h3 className="title">
            Щодо виписки та отримання інсулінів в період воєнного стану
          </h3>
          <div className="infobox">
            <p className="text">
              Доплачувати за інсулін поки що не потрібно — у період воєнного
              стану його надаватимуть безкоштовно. 14 березня, 2022 року Кабінет
              міністрів ухвалив у період воєнного стану надавати препарати
              інсуліну безкоштовно. Раніше за них треба було доплачувати.
            </p>
            <p className="text">
              Про це повідомили у Центрі протидії дезінформації при Раді
              національної безпеки та оборони України. Зміни у питаннях
              реімбурсації лікарських засобів, зокрема щодо доплат за препарати
              інсуліну суб'єктам господарювання за договорами про реімбурсацію
              надають повне відшкодування вартості препаратів інсуліну із
              держбюджету; доплату з пацієнтів не стягують, положення про
              часткове відшкодування вартості препаратів інсуліну, розрахунку
              суми доплати за препарати інсуліну, що сплачуються пацієнтами
              аптечному закладу, не застосовуються.
            </p>
            <p className="text">
              У Національній службі здоров'я України уточнили, що всі 47
              препаратів інсуліну, включені до Реєстру лікарських засобів, що
              підлягають реімбурсації за Програмою медичних гарантій, наразі є
              повністю безкоштовними на період дії воєнного стану.
            </p>
            <p className="text">
              Для того, щоб отримати їх безкоштовно, у пацієнта має бути
              паперовий або електронний рецепт.
            </p>
          </div>
        </section>

        <section className="content">
          <h3 className="title">
            Департамент охорони здоров'я та медичних послуг Черкаської міської
            ради інформує:
          </h3>
          <div className="textbox">
            <p className="text">
              Національна компанія з вибору лікаря стартувала в місті Черкаси
              02.04.2018 року. Обрати свого лікаря - означає визначитись з
              терапевтом, педіатром чи сімейним лікарем, якому Ви готові
              довірити своє здоров’я.
            </p>
            <p className="text">
              Підписавши з ним декларацію, Ви повідомляєте державу, що гроші на
              Ваше обслуговування повинні направлятися саме у той заклад в якому
              працює вибраний Вами лікар.
            </p>
            <h4 className="subtitle">
              {`Станом нa ${date} по закладу зареєстровано ${depsSum} декларацій`}
            </h4>
            <p className="text">
              Щодо кількості заключних декларацій по кожному конкретному лікарю
              можна дізнатись за посиланням - на &nbsp;
              <a
                href="https://nszu.gov.ua/e-data/dashboard/pmd-map"
                rel="noopener noreferrer"
                className="link"
                target="_blank"
              >
                сайті НЗСУ
              </a>
              &nbsp; або у розділі «Публічна інформація» - &nbsp;
              <Link to="/public-info#declarations-amount" className="navlink">
                «кількість заключних декларацій по лікарям»
              </Link>
            </p>
          </div>
        </section>
        <section className="content">
          <h3 className="title">Вибір сімейного лікаря</h3>
          <div className="textbox">
            <p className="text">
              Шановні мешканці м. Черкаси! Згідно з наказом МОЗ України від
              19.03.2018 № 503 «Про затвердження Порядку вибору лікаря, який
              надає первинну медичну допомогу, та форми декларації про вибір
              лікаря, який надає первинну медичну допомогу» сімейними лікарями
              та дільничними педіатрами Черкаських міських центрів первинної
              медико-санітарної допомоги (у минулому – міські поліклініки)
              проводиться укладання декларацій з пацієнтами.
            </p>
            <p className="text">
              У зв’язку з необхідністю часу заповнення декларації (15–20 хв.)
              просимо додатково попередньо записуватися до вибраних Вами лікарів
              (особисто у реєстратурах або за телефонами реєстратур центрів
              первинної медико-санітарної допомоги).
            </p>
            <p className="text">
              Мешканцям 14 років та дорослим при собі мати оригінали наступних
              документів, за можливості їх копії:
            </p>
            <ul className="items m-l-3em">
              <li className="items__item">Паспорт.</li>
              <li className="items__item">
                Ідентифікаційний код платника податків (РНОКПП).
              </li>
              <li className="items__item">
                Законні представники пацієнта додатково подають документи, що
                посвідчують його особу (паспорт, РНОКПП) та повноваження
                законного представника, відповідно до законодавства.
              </li>
            </ul>
            <p className="text">
              Для дітей до 14 років батьки (законні представники) надають лікарю
              оригінали документів, за можливості їх копії:
            </p>
            <ul className="items m-l-3em">
              <li className="items__item">
                Свідоцтво про народження дитини (діти до 14 років).
              </li>
              <li className="items__item">
                Паспорт (батьків, законного представника).
              </li>
              <li className="items__item">
                Ідентифікаційний код платника податків (РНОКПП) батьків,
                законного представника.
              </li>
              <li className="items__item">
                Посвідчення законного представника, відповідно до законодавства.
              </li>
            </ul>
            <p className="text">
              Інформацію про сімейних лікарів та педіатрів розміщено на сайтах
              Департаменту охорони здоров’я та медичних послуг Черкаської
              міської ради та міських центрів первинної медико-санітарної
              допомоги.
            </p>
            <p className="text">
              Переглянути інформацію щодо сімейних лікарів "Черкаського міського
              центру первинної медико-санітарної допомоги" можна у розділі -
              &nbsp;
              <Link to="/about/employees" className="navlink">
                «Працівники»
              </Link>
            </p>
          </div>
        </section>
        <section className="content">
          <h3 className="title">Урядова програма «Доступні ліки»</h3>
          <div className="textbox">
            <p className="text">
              1 квітня 2017 року стартує Урядова програма «Доступні ліки».
              Пацієнти зможуть отримати ліки від серцево-судинних захворювань,
              діабету ІІ типу та бронхіальної астми безкоштовно або з незначною
              доплатою.
            </p>
            <p className="text">
              Мета програми «Доступні ліки» – забезпечити кожного громадянина
              України необхідними лікарськими засобами, які покращать якість
              їхнього життя і запобіжать виникненню ускладнень та передчасної
              смертності.
            </p>
            <p className="text">
              За рецептом звертайтесь до свого сімейного лікаря.
            </p>
            <div className="subtitle">
              <Link
                to="/public-info#available-medicine-program"
                className="navlink"
              >
                Список аптек, які відпускають ліки по програмі «Доступні ліки»
              </Link>
            </div>
            <div className="subtitle">
              <a
                href={medCabUrl}
                rel="noopener noreferrer"
                className="link"
                target="_blank"
              >
                Online запис на прийом до лікаря
              </a>
            </div>
            <div className="subtitle">
              <Link to="/public-info#paid-services-rate" className="navlink">
                Про встановлення тарифів на платні послуги
              </Link>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};
