import React, {useEffect} from 'react';
import './css/ErrorPage.css';
import { Link } from 'react-router-dom';

export const ErrorPage: React.FC = () => {

    const pageTitle: string = 'Сторінка не знайдена';

    useEffect(() => {
        document.title = pageTitle;
    }, []);

    return (
        <div className="ErrorPage">
            <h3 className="title-custom">
                { pageTitle }
            </h3>
            <div className="container-custom">
                <div className="iconbox">
                    <i className="fas fa-ambulance" />
                </div>
                <div className="textbox">
                    <span className="text">
                        Це могло статися з наступних причин:
                    </span>
                    <ul className="items">
                        <li className="items__item">
                            сторінка тимчасово недоступна або видалена;
                        </li>
                        <li className="items__item">
                            помилка URL адреси.
                        </li>
                    </ul>
                    <Link to="/">
                        <button className="backToHome">
                            Повернутися на Головну
                        </button>
                    </Link>
                </div>
            </div>
        </div>
    )
}
