// System
import React from 'react';

// App
import './css/ScrollToUpButton.css';
import {useHistory, useLocation} from "react-router-dom";

export const ScrollToUpButton = () => {
    // @ts-ignore
    const location: Location<unknown> = useLocation();
    const history = useHistory();

    window.onscroll = (): void => {
        const btn: HTMLElement | null = document.querySelector(".ScrollToUpButton");

        if (!!btn) {
            const buttonShouldBeHidden: boolean = window.pageYOffset < 150;

            btn.style.right = buttonShouldBeHidden ? "-50px" : "10px";
            btn.style.opacity = buttonShouldBeHidden ? "0" : "1";
        }
    };

    /**
     * Scrolls page to top
     */
    const scroll = (): void => {
        history.push(location.href); // remove hash

        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    return (
        <div className="ScrollToUpButton"
             onClick={scroll}
        >
            <i className="fas fa-arrow-up" />
        </div>
    )
}
