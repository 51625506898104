// System
import { combineReducers } from 'redux';

// reducers
import dateReducer from './date';
import declarationsReducer from './declarations';

const rootReducer = combineReducers({
    date: dateReducer,
    declarations: declarationsReducer
});

export default rootReducer;
