// System
import React, { useCallback, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { useLocation } from "react-router-dom";

// App
import "./css/Header.css";
import logoDefault from "./../../assets/images/logo/logo-with-bg.jpg";
import logoChristmas from "./../../assets/images/logo/logo-with-bg-christmas.jpg";
import { IRoutes, ROUTES } from "../../helpers/data/routes";
import { useGetAndFormatDate } from "../../hooks/useGetAndFormatDate";

interface INavLink {
  route: string;
  name: string;
}

const routes: IRoutes = ROUTES;

const navLinks: INavLink[] = [
  {
    route: routes.MAIN,
    name: "Головна",
  },
  {
    route: routes.ANNOUNCEMENTS,
    name: "Оголошення",
  },
  {
    route: routes.VPO,
    name: "Для ВПО",
  },
  {
    route: routes.DEPARTMENTS,
    name: "Відділення",
  },
  {
    route: routes.PUBLIC_INFO,
    name: "Публічна інформація",
  },
  {
    route: routes.ARTICLES,
    name: "Статті",
  },
  {
    route: routes.ONLINE_RECEPTION,
    name: "Інтернет-приймальня",
  },
];

const navDropdownLinks: INavLink[] = [
  {
    route: routes.ADMINISTRATION,
    name: "Адміністрація",
  },
  {
    route: routes.EMPLOYEES,
    name: "Працівники",
  },
  {
    route: routes.CONTACTS,
    name: "Контакти",
  },
];

export const Header: React.FC = () => {
  const { day, month } = useGetAndFormatDate();
  const [logo, setLogo] = useState<string>(logoDefault);

  const location = useLocation();
  const [dropdownState, setDropdownState] = useState(false);

  /**
   * Get current URL
   */
  const getURL = useCallback(() => {
    const route: string = location.pathname;
    const routesList: Array<string> = [
      routes.ADMINISTRATION,
      routes.EMPLOYEES,
      routes.CONTACTS,
    ];
    setDropdownState(routesList.includes(route));
  }, [location.pathname]);

  useEffect(() => {
    getURL();
  }, [getURL]);

  useEffect(() => {
    // Depending on date, set Christmas or default logo
    setLogo(month === 1 && day < 8 ? logoChristmas : logoDefault);
  }, [setLogo]);

  return (
    <header className="Header">
      <div className="h-container">
        <img className="logo" src={logo} alt="logo" />
      </div>
      <Navbar collapseOnSelect expand="xl" variant="light" className="h-navbar">
        <Container className="h-container">
          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            className="nav-controls"
          >
            <span className="navbar-toggler-icon" />
            <div className="close-icon py-1">✖</div>
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav>
              {navLinks.map(({ route, name }: INavLink) => (
                <NavLink
                  exact={route === routes.MAIN}
                  to={route}
                  activeClassName="navlink-active"
                  className="navlink"
                  onClick={() => getURL()}
                  key={name}
                >
                  {name}
                </NavLink>
              ))}
              {/* Preventing navigation to '/about' */}
              <NavDropdown
                title="Про нас"
                id="basic-nav-dropdown"
                className={dropdownState ? "navlink navlink-active" : "navlink"}
              >
                {navDropdownLinks.map(({ route, name }: INavLink) => (
                  <NavDropdown.Item as={"div"} key={name}>
                    <NavLink
                      exact={route === routes.MAIN}
                      to={route}
                      activeClassName="navlink-active"
                      className="navlink"
                      onClick={() => getURL()}
                    >
                      {name}
                    </NavLink>
                  </NavDropdown.Item>
                ))}
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
};
