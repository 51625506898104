export const getDate = () => {
    return {
        type: 'GET_DATE'
    };
}

export const getDeclarations = () => {
    return {
        type: 'GET_DECLARATIONS'
    };
}
