// System
import React from "react";
import { HashLink as Link } from "react-router-hash-link";

// App
import "./css/Footer.css";
import { IProps } from "../../interfaces/IProps";
import { useGetAndFormatDate } from "../../hooks/useGetAndFormatDate";

const author: string = "Lirys";

export const Footer: React.FC<IProps> = ({ titles }: IProps) => {
  const { year } = useGetAndFormatDate();

  return (
    <footer className="Footer">
      <div className="col">
        <p className="text">Усі права захищено © 2015 - {year}</p>
        <p className="text">
          Designed by <span className="author">{author}</span>
        </p>
      </div>
      <div className="col">
        <p className="text">{titles!.title}</p>
        <p className="text">{titles!.subtitle}</p>
        <hr />
        <Link to="/about/contacts" className="navlink">
          <p className="text">Контактна інформація</p>
        </Link>
      </div>
      <div className="col">
        <p className="text">Наші соціальні мережі</p>
        <a
          href="https://www.facebook.com/groups/349615109195502"
          rel="noopener noreferrer"
          className="link"
          target="_blank"
        >
          <i className="fab fa-facebook" />
        </a>
      </div>
    </footer>
  );
};
