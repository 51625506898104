// System
import React from "react";

// App
import "./css/VPO.css";
import medServicesVpo from "../../assets/docs/med-services-vpo.pdf";
import psychoServicesVpo from "../../assets/docs/psycho-services-vpo.pdf";
import famDoctorVpo from "../../assets/docs/fam-doctor-vpo.pdf";
import pregnancyVpo from "../../assets/docs/pregnancy-vpo.pdf";

export const VPO: React.FC<any> = () => {
  return (
    <div className="VPO">
      <div className="container-custom">
        <section className="content">
          <h3 className="title">
            Ведення вагітності та медична допомога при пологах для ВПО
          </h3>
          <div className="textbox">
            <div className="video-frame">
              <iframe
                className="video"
                title="pregnancy-vpo"
                src={pregnancyVpo}
                width={500}
                height={600}
              ></iframe>
            </div>
          </div>
        </section>
        <section className="content">
          <h3 className="title">Психологічна допомога для ВПО</h3>
          <div className="textbox">
            <div className="video-frame">
              <iframe
                className={"video"}
                title={"psycho-services-vpo"}
                src={psychoServicesVpo}
                width={500}
                height={600}
              ></iframe>
            </div>
          </div>
        </section>
        <section className="content">
          <h3 className="title">Медична допомога для ВПО</h3>
          <div className="textbox">
            <div className="video-frame">
              <iframe
                className={"video"}
                title={"med-services-vpo"}
                src={medServicesVpo}
                width={500}
                height={600}
              ></iframe>
            </div>
          </div>
        </section>
        <section className="content">
          <h3 className="title">Сімейний лікар для ВПО</h3>
          <div className="textbox">
            <div className="video-frame">
              <iframe
                title="fam-doctor-vpo"
                src={famDoctorVpo}
                width={500}
                height={600}
                allow="autoplay"
              ></iframe>
            </div>
          </div>
        </section>
        <div className="content">
          <h3 className="title">
            Про можливість отримання безоплатних ліків та медичної допомоги для
            ВПО
          </h3>
          <h4 className="subtitle">
            Більше деталей -{" "}
            <a
              href="https://shorturl.at/dxy68"
              target="_blank"
              rel="noreferrer"
            >
              за посиланням
            </a>
            .
          </h4>
          <div className="textbox">
            <div className="video-frame">
              <iframe
                title="vpo-doc-1"
                src="https://drive.google.com/file/d/1Trd9vhdEXw66-btVXIVXKUktUoNYYJ34/preview"
                width={500}
                height={600}
                allow="autoplay"
              ></iframe>
              <iframe
                title="vpo-doc-2"
                src="https://drive.google.com/file/d/1tMzYT_0-FB_nfUAdCAObqwi3MF5vxDFW/preview"
                width={500}
                height={600}
                allow="autoplay"
              ></iframe>
            </div>
          </div>
        </div>
        <section className="content">
          <h3 className="title">
            Внутрішньо переміщені особи можуть отримувати медичну допомогу за
            місцем проживання та без декларацій
          </h3>
          <div className="textbox">
            <p className="text">
              Військова агресія рф змушує багатьох українців залишати власні
              домівки. Понад 8 млн наших співвітчизників уже стали внутрішньо
              переміщеними особами (ВПО).
            </p>
            <p className="text">
              Забезпечення усіх громадян, які покинули місця постійного
              проживання і тимчасово змушені проживати в інших регіонах країни,
              якісним і повноцінним медичним обслуговуванням — одне з щоденних
              завдань системи охорони здоров’я.
            </p>
            <p className="text">
              Так, наразі, спрощений доступ українців до первинної медичної
              допомоги: внутрішньо переміщені особи можуть бути прийняті у
              будь-якій лікарні, якщо вона здатна забезпечити необхідне
              лікування, наявність декларації для цього не обов’язкова.
            </p>
            <p className="text">
              У лікаря, який надає первинну медичну допомогу, можна отримати:
            </p>
            <ul className="items m-l-3em">
              <li className="items__item">
                динамічне спостереження за станом здоров'я;
              </li>
              <li className="items__item">
                діагностику та лікування захворювань;
              </li>
              <li className="items__item">базові тести та аналізи;</li>
              <li className="items__item">
                направлення до лікарів вузької спеціалізації чи на обстеження;
              </li>
              <li className="items__item">
                <a
                  href="https://moz.gov.ua/article/news/paliativna-dopomoga-dlja-pacientiv-z-vazhkimi-ta-nevilikovnimi-hvorobami-zalishaetsja-bezoplatnoju"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  паліативну допомогу
                </a>
                ;
              </li>
              <li className="items__item">
                рецепти за програмою «Доступні ліки» та інсулін (якщо це
                повторний рецепт);
              </li>
              <li className="items__item">
                {"щеплення відповідно до "}
                <a
                  href="https://moz.gov.ua/article/immunization/kalendar-profilaktichnih-scheplen"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  календаря профілактичних щеплень
                </a>
                .
              </li>
            </ul>
            <p className="text">
              <u>Знайти адресу найближчої лікарні</u> допоможе оператор
              контакт-центру НСЗУ за номером <b>16-77</b>.
            </p>
            <p className="text">
              {
                "Наголошуємо, що медзаклади, які працюють за програмою медичних гарантій (ПМГ), повинні надавати необхідну "
              }
              <a
                href="https://moz.gov.ua/article/news/medzakladi-scho-pracjujut-za-pmg-povinni-diagnostuvati-ukrainciv-ta-nadavati-neobhidnu-dopomogu-bezkoshtovno"
                target="_blank"
                rel="noopener noreferrer"
              >
                медичну допомогу, у тому числі ВПО
              </a>
              {
                ', безоплатно. Адже "Програма медичних гарантій – 2022" продовжує діяти, 38 пакетів ПМГ, серед яких — лікування '
              }
              <a
                href="https://moz.gov.ua/article/health/bezoplatni-medichni-poslugi-u-vipadku-insultu-pid-chas-vijni"
                target="_blank"
                rel="noopener noreferrer"
              >
                інсульту
              </a>
              {" та "}
              <a
                href="https://moz.gov.ua/article/health/nadannja-medichnoi-dopomogi-pacientam-z-infarktom-v-umovah-voennogo-stanu"
                target="_blank"
                rel="noopener noreferrer"
              >
                інфаркту
              </a>
              {", дослідження для раннього "}
              <a
                href="https://moz.gov.ua/article/news/shist-bezoplatnih-doslidzhen-dlja-rannogo-vijavlennja-onkologii"
                target="_blank"
                rel="noopener noreferrer"
              >
                виявлення онкології
              </a>
              {
                ", медична допомога породіллям, медична допомога новонародженим у складних неонатальних випадках та ін. — продовжують працювати і під час воєнного стану."
              }
            </p>
            <p className="text">
              <u>Програма «Доступні ліки»</u> теж діє. Пацієнти, які потребують
              амбулаторного лікування серцево-судинних захворювань, цукрового
              діабету першого/другого типу, нецукрового діабету, бронхіальної
              астми, розладів психіки та поведінки, епілепсії, можуть отримати
              лікарські засоби, що входять до програми, безоплатно або з
              невеликою доплатою.
            </p>
            <p className="text">
              {
                "Для забезпечення доступу пацієнтів до цих ліків на час війни були спрощені процедури їх отримання, зокрема пацієнти можуть отримувати такі ліки "
              }
              <a
                href="https://moz.gov.ua/article/news/usi-apteki-majut-prijmati-i-elektronni-i-paperovi-recepti-na-likarski-zasobi"
                target="_blank"
                rel="noopener noreferrer"
              >
                за електронним або за паперовим рецептом
              </a>
              {
                ". Усі 47 препаратів інсуліну, які входять до програми реімбурсації, можна отримувати без доплат (детальніше про спрощення — за "
              }
              <a
                href="https://moz.gov.ua/article/news/programa-dostupni-liki-prodovzhue-pracjuvati-v-umovah-voennogo-stanu-"
                target="_blank"
                rel="noopener noreferrer"
              >
                посиланням
              </a>
              {")."}
            </p>
            <p className="text">
              {
                "Якщо внутрішньо переміщена особа (дитина чи дорослий) пропустила планову вакцинацію за Національним календарем щеплень, її "
              }
              <a
                href="https://moz.gov.ua/article/news/jak-nadoluzhuvati-propuscheni-scheplennja"
                target="_blank"
                rel="noopener noreferrer"
              >
                можна надолужити
              </a>
              {
                ". Для цього достатньо звернутися до свого сімейного лікаря/педіатра або до сімейного лікаря/педіатра найближчого закладу охорони здоров'я."
              }
            </p>
            <p className="text">
              {"Також на період дії воєнного стану суттєво "}
              <a
                href="https://moz.gov.ua/article/news/osobam-z-invalidnistju-prohoditi-povtornij-ogljad-pid-chas-vijni-ne-potribno"
                target="_blank"
                rel="noopener noreferrer"
              >
                спрощено процедуру встановлення та продовження інвалідності
              </a>
              {
                ". Наразі для отримання соціальних виплат особам з інвалідністю проходити повторний огляд для підтвердження інвалідності не потрібно."
              }
            </p>
            <p className="text">
              Звертаємо увагу, що укладання декларації між пацієнтом –
              внутрішньо переміщеною особою та лікарем, який оформляє
              направлення на МСЕК, теж не потрібно, законодавчі підстави
              вимагати її відсутні. Лікарі не мають права вимагати у пацієнтів
              прибути безпосередньо на повторний огляд. Усі виплати та
              відповідні пільги зберігаються за особою до закінчення строку
              проходження повторного огляду.
            </p>
            <p className="text">
              {"Українці, які живуть із ВІЛ, теж можуть "}
              <a
                href="https://moz.gov.ua/article/news/likuvannja-vil-za-kordonom-i-v-ukraini"
                target="_blank"
                rel="noopener noreferrer"
              >
                отримати антиретровірусну терапію
              </a>
              {" за місцем фактичного перебування."}
            </p>
            <p className="text">
              Крім того, якщо через певні причини людина не має можливості
              відвідати медичний заклад, у період воєнного стану національний
              контакт-центр Міністерства охорони здоров’я надає{" "}
              <u>безоплатні медичні консультації</u>. Щоб отримати
              онлайн-допомогу від фахівців різних спеціальностей, необхідно
              звернутися за номером <b>0-800-60-20-19</b>.
            </p>
            <p className="text">
              {
                "Зауважимо, що у разі, коли українці потребують високоспеціалізованої медичної та реабілітаційної допомоги, доступ до якої обмежений через збройну агресію росії проти України, Міністерство охорони здоров’я розробило "
              }
              <a
                href="https://moz.gov.ua/article/news/jak-zdijsnjuvatimetsja-medichna-evakuacija-gromadjan-ukraini"
                target="_blank"
                rel="noopener noreferrer"
              >
                алгоритм медичної евакуації
              </a>
              {". Усі "}
              <a
                href="https://docs.google.com/document/d/1dQFzT5vVJqh1XEhfyDsDHboQnELKWc7u6ih0kDq8gS8/edit"
                target="_blank"
                rel="noopener noreferrer"
              >
                евакуаційні рейси
              </a>
              {
                ", організовані МОЗ у співпраці з Єврокомісією, до більш безпечних регіонів України чи за кордон здійснюються "
              }
              <a
                href="https://moz.gov.ua/article/news/evakuacija-poranenih-ukrainciv-ta-onkohvorih-ditej-na-likuvannja-za-kordon---bezoplatna"
                target="_blank"
                rel="noopener noreferrer"
              >
                безоплатно для пацієнтів
              </a>
              .
            </p>
          </div>
        </section>
      </div>
    </div>
  );
};
