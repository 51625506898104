// System
import React, { Component, lazy, Suspense } from "react";
import { HashRouter, Switch, Route } from "react-router-dom";
import PulseLoader from "react-spinners/PulseLoader";

// App
import "./App.css";
import { ErrorPage } from "./components/ErrorPage/ErrorPage";
import { Header } from "./components/Header/Header";
import { Footer } from "./components/Footer/Footer";
import { Main } from "./components/Main/Main";
import { ScrollToUpButton } from "./components/ScrollToUpButton/ScrollToUpButton";
import { ROUTES } from "./helpers/data/routes";
import { VPO } from "./components/VPO/VPO";

// lazy imports
const Announcements = lazy(() =>
  import("./components/Announcements/Announcements").then(
    ({ Announcements }) => ({ default: Announcements }),
  ),
);
const Administration = lazy(() =>
  import("./components/Administration/Administration").then(
    ({ Administration }) => ({ default: Administration }),
  ),
);
const Employees = lazy(() =>
  import("./components/Employees/Employees").then(({ Employees }) => ({
    default: Employees,
  })),
);
const Contacts = lazy(() =>
  import("./components/Contacts/Contacts").then(({ Contacts }) => ({
    default: Contacts,
  })),
);
const Departments = lazy(() =>
  import("./components/Departments/Departments").then(({ Departments }) => ({
    default: Departments,
  })),
);
const PublicInfo = lazy(() =>
  import("./components/PublicInfo/PublicInfo").then(({ PublicInfo }) => ({
    default: PublicInfo,
  })),
);
const Articles = lazy(() =>
  import("./components/Articles/Articles").then(({ Articles }) => ({
    default: Articles,
  })),
);
const Commands = lazy(() =>
  import("./components/Commands/Commands").then(({ Commands }) => ({
    default: Commands,
  })),
);
const OnlineReception = lazy(() =>
  import("./components/OnlineReception/OnlineReception").then(
    ({ OnlineReception }) => ({ default: OnlineReception }),
  ),
);
// ******

export default class App extends Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      titles: {
        title: "Комунальне некомерційне підприємство",
        subtitle: `"Третій Черкаський міський центр первинної медико-санітарної допомоги"`,
      },
    };
  }

  render() {
    return (
      <HashRouter basename={window.location.pathname || " "}>
        <div className="App">
          <Header />
          <ScrollToUpButton />
          <Suspense
            fallback={
              <div className="pulseLoader">
                Loading...
                <PulseLoader
                  css={`
                    display: block;
                    margin: 0 auto;
                    border-color: red;
                  `}
                  size={15}
                  color={"#0a090a"}
                  loading={true}
                />
              </div>
            }
          >
            <Switch>
              <Route exact path={ROUTES.MAIN} render={() => <Main />} />
              <Route
                path={ROUTES.ANNOUNCEMENTS}
                render={() => <Announcements />}
              />
              <Route
                path={ROUTES.ADMINISTRATION}
                render={() => <Administration />}
              />
              <Route path={ROUTES.EMPLOYEES} render={() => <Employees />} />
              <Route
                path={ROUTES.CONTACTS}
                render={() => <Contacts titles={this.state.titles} />}
              />
              <Route path={ROUTES.DEPARTMENTS} render={() => <Departments />} />
              <Route path={ROUTES.PUBLIC_INFO} render={() => <PublicInfo />} />
              {/*<Route path={ROUTES.COMMANDS} render={() => <Commands /> } />*/}
              <Route path={ROUTES.ARTICLES} render={() => <Articles />} />
              <Route path={ROUTES.VPO} render={() => <VPO />} />
              <Route
                path={ROUTES.ONLINE_RECEPTION}
                render={() => <OnlineReception />}
              />
              <Route component={ErrorPage} />
            </Switch>
          </Suspense>
          <Footer titles={this.state.titles} />
        </div>
      </HashRouter>
    );
  }
}
